<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row justify="end">
      <v-col>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Wyszukaj"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col style="text-align: right">
        <v-btn
          color="secondary_dark"
          dark
          depressed
          tile
          :to="'/pakiet-nowy/'+id"
          v-if="$store.state.user_permissions.package_create"
        >
          Dodaj pakiet
        </v-btn>
      </v-col>
    </v-row>
    
    <v-card>
    <v-data-table
        class="classic_table"
        :headers="headers"
        :items="list"
        :search="search"
        item-key="name"
        @click:row="rowClick"
        :footer-props="{
          itemsPerPageText : 'Elementów na stronie',
          itemsPerPageAllText : 'Wszystkie',
          itemsPerPageOptions : [15,45,90,-1],
        }"
      >
        <template v-slot:no-data>Brak danych</template>
        <template v-slot:no-results>Brak wyników</template>
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} z {{ itemsLength}}
        </template>
        <template
          v-slot:item.price="{ item }"
        >
          <span>{{item.price}} zł</span> 
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    id: 0,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista Klientów - pakiety',
        disabled: false,
        to: '/pakiety',
      },
      {
        text: 'Lista pakietów Klienta',
        disabled: true,
        to: '/pakiet',
      },
    ],

    headers: [
      {
        text: 'Kategoria pakietu',
        align: 'start',
        value: 'category_name',
      },
      { text: 'Kwota (netto)', value: 'price' },
      { text: 'Data wygaśnięcia', value: 'end_data' },
    ],

    list: [
      {
        id: 1,
        done: false,
        category: 'SEO',
        finished_at: 'Nazwa Klienta',
        full_name: 'Pełna nazwa',
        contact_person: 'Imię Nazwisko',
        nip: '14012021',
        number: '12313131233'
      },
    ],
    
    search: ''
  }),
  methods: {
    rowClick(value) {
      if(this.$store.state.user_permissions.package_show)
        this.$router.push('/pakiet-zobacz/'+value.id);
    },
    
  },
  mounted(){
    this.id = this.$route.params.id;
    console.log(this.id);

    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/package_user/'+this.id, data: {}, method: 'GET' })
      .then(resp => {
        this.list = resp.data;
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.classic_table {
  margin-top: 25px;
}
.paid {
  background-color: green;
  color: white;
  padding: 5px 10px;
  text-align: center;
}
.not_paid {
  background-color: red;
  color: white;
  padding: 5px;
  text-align: center;
}
</style>